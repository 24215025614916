/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Blog
import { BlogFilter } from 'components/shared/BlogIk'

const FilterButton = styled(BlogFilter)`
  width: 125px;
  height: ${(props) => props.theme.font.size.xl};
  border-radius: 15px;
  font-size: ${(props) => props.theme.font.size.sm};
  font-family: ${(props) => props.theme.font.family.secondary};
  line-height: ${(props) => props.theme.font.size.xl};
  font-style: italic;
  border: 1px solid ${(props) => props.theme.color.text.secondary};
  color: ${(props) => props.theme.color.text.secondary};
  margin:  0 10px;
  background: white;
  cursor: pointer;
  transition: all 300ms ease-in;
  text-align: center;

  &[data-active="1"] {
    background: ${(props) => props.theme.color.face.secondary};
    color: ${props => props.theme.color.text.light};
  }
  
  &:hover{
    background: ${(props) => props.theme.color.text.secondary};
    color: white;
  }
`

export default function BlogFilters() {
  const {
    categories
  } = useStaticQuery(graphql`
    {
      categories: allWordpressCategory(filter: {wordpress_id: {ne: 1}}) {
        edges {
          node {
            wordpress_id
            name
          }
        }
      }
    }
  `)

  return categories.edges.map(({ node }) => {
    return (
      <FilterButton 
        key={node.wordpress_id}
        id={node.wordpress_id}
      >
        {node.name}
      </FilterButton>
    )
  })
}
